import { ref } from 'vue'
export const iptData = ref([
  {
    type: 'input',
    placeholder: '标题',
    value: null,
    prop: 'name',
    width: '13%',
  },
  {
    type: 'input',
    placeholder: '摘要',
    value: null,
    prop: 'summary',
    width: '13%',
  },
  {
    type: 'input',
    placeholder: '流程实例ID',
    value: null,
    prop: 'flowInstanceId',
    width: '13%',
  },
  {
    type: 'input',
    placeholder: '标记',
    value: null,
    prop: 'nodeRemark',
    width: '13%',
  },
  {
    type: 'select',
    placeholder: '类型',
    value: null,
    prop: 'flowType',
    width: '13%',
    opt:[
      {
        label: '客户结算(收款)',
        value: 0
      },
      {
        label: '客户结算(付款)',
        value: 1
      },
    ]
  },
  {
    type: 'select',
    placeholder: '审核状态',
    value: null,
    prop: 'flowInstanceStatus',
    width: '13%',
    opt:[
      {
        label: '审批中',
        value: 1
      },
      {
        label: '审批完成',
        value: 6
      },
      {
        label: '审批撤回',
        value: 4
      },
      {
        label: '审批拒绝',
        value: 5
      },
    ]
  },
  {
    type: 'selectTime',
    placeholder: '提交时间起',
    value: null,
    prop: 'submitStartTime',
    width: '13%',
  },
  {
    type: 'selectTime',
    placeholder: '提交时间止',
    value: null,
    prop: 'submitEndTime',
    width: '13%',
  },
  {
    type: 'selectTime',
    placeholder: '审批时间起',
    value: null,
    prop: 'doneStartTime',
    width: '13%',
  },
  {
    type: 'selectTime',
    placeholder: '审批时间止',
    value: null,
    prop: 'doneEndTime',
    width: '13%',
  },
])
export const iptDataFlow = ref([
  {
    type: 'input',
    placeholder: '标题',
    value: null,
    prop: 'name',
    width: '13%',
  },
  {
    type: 'input',
    placeholder: '摘要',
    value: null,
    prop: 'summary',
    width: '13%',
  },
  {
    type: 'input',
    placeholder: '流程实例ID',
    value: null,
    prop: 'flowInstanceId',
    width: '13%',
  },
  {
    type: 'select',
    placeholder: '类型',
    value: null,
    prop: 'flowType',
    width: '13%',
    opt:[
      {
        label: '承运商结算(收款)',
        value: 0
      },
      {
        label: '承运商结算(付款)',
        value: 1
      },
    ]
  },
  {
    type: 'select',
    placeholder: '审核状态',
    value: null,
    prop: 'flowInstanceStatus',
    width: '13%',
    opt:[
      {
        label: '审批中',
        value: 1
      },
      {
        label: '审批完成',
        value: 6
      },
      {
        label: '审批撤回',
        value: 4
      },
      {
        label: '审批拒绝',
        value: 5
      },
    ]
  },
  {
    type: 'selectTime',
    placeholder: '提交时间起',
    value: null,
    prop: 'submitStartTime',
    width: '13%',
  },
  {
    type: 'selectTime',
    placeholder: '提交时间止',
    value: null,
    prop: 'submitEndTime',
    width: '13%',
  },
  {
    type: 'selectTime',
    placeholder: '审批时间起',
    value: null,
    prop: 'doneStartTime',
    width: '13%',
  },
  {
    type: 'selectTime',
    placeholder: '审批时间止',
    value: null,
    prop: 'doneEndTime',
    width: '13%',
  },
])
export const columns =ref([
  {
    title: '标题',
    dataIndex: 'instanceName',
    align: 'center',
  },
  {
    title: '摘要',
    dataIndex: 'summary',
    align: 'center',
    slots:{
      customRender: 'summary'
    }
  },
  {
    title: '发起人',
    dataIndex: 'sponsor',
    align: 'center',
  },
  {
    title: '发起时间',
    dataIndex: 'createTime',
    align: 'center',
  },
  {
    title: '最后操作时间',
    dataIndex: 'lastTime',
    align: 'center',
  },
  {
    title: '流程状态',
    dataIndex: 'flowInstanceStatus',
    align: 'center',
    slots:{
      customRender:'flowInstanceStatus'
    }
  },
  {
    title: '标记',
    dataIndex: 'nodeRemark',
    align: 'center',
    slots:{ customRender: 'nodeRemark' }
  },

])

export const columnsOther =ref([
  {
    title: '标题',
    dataIndex: 'instanceName',
  },
  {
    title: '摘要',
    dataIndex: 'summary',
    align: 'center',
    slots:{
      customRender: 'summary'
    }
  },
  {
    title: '发起人',
    dataIndex: 'sponsor',
    align: 'center',
  },
  {
    title: '发起时间',
    dataIndex: 'createTime',
    align: 'center',
  },
  {
    title: '最后操作时间',
    dataIndex: 'lastTime',
    align: 'center',
  },
  {
    title: '流程状态',
    dataIndex: 'flowInstanceStatus',
    align: 'center',
    slots:{
      customRender:'flowInstanceStatus'
    }
  },
])